import "core-js/modules/es6.array.sort";

/*
import LocalStorage from 'quasar/src/plugins/LocalStorage.js';
import VueI18n from 'vue-i18n'

let locale = LocalStorage.getItem('locale')
console.log('locale', locale)
if (locale) { VueI18n.locale = locale }
*/
// eslint-disable-next-line no-extend-native
Array.prototype.shuffled = function () {
  return this.map(function (n) {
    return [Math.random(), n];
  }).sort().map(function (n) {
    return n[1];
  });
};