export default {
  messages: {
    // account_created: 'Please check your email to verify your registration. This sometimes can take up to 10 minutes. Please check the spam folder if the email has not been received.',
    // password_forgot: 'Please check your email to reset your password.',
    // verification_success: 'Your e-mail has been verified. You can now login.',
    // verification_failed: 'Verification has failed.',
    // password_recover_success: 'Your password has been reset. You can now login with the new password.',
    // logout_confirmation: 'Are you sure you want to log out?',
    // confirm: 'Confirm',
    // update_available: 'A new update is available. Click on ok to reload the page.',
    // account_signed_out: 'You have been signed out',
    // account_updated: 'Account succesfully updated'
    account_created: 'Account erstellt',
    password_forgot: 'Bitte schauen Sie in Ihre E-Mail um Ihr Passwort zurück zusetzen',
    account_signed_out: 'Sie wurden ausgeloggt',
    account_updated: 'Account aktualisiert',
    verification_success: 'Ihre E-Mail ist verifiziert. Sie können sich nun einloggen.',
    verification_failed: 'Verifikation missglückt',
    password_recover_success: 'Ihr Passwort wurde zurückgesetzt. Sie können sich nun mit dem neuen Passwort anmelden.',
    logout_confirmation: 'Sind Sie sicher, dass Sie sich ausloggen wollen?',
    login_confirmation: 'Sie haben sich erfolgreich wieder eingeloggt. Willkommen zurück!',
    login_failed: 'E-Mail- und Passwortkombination nicht erkannt',
    confirm: 'Bestätigen',
    update_available: 'Ein neues Update ist verfügbar. Klicken Sie auf OK um die Seite neu zu laden.',
    url_copied_to_clipboard: 'Der Karten-URL wurde in die Zwischenablage kopiert.',
    add_area_account_required: 'Um ein neues Gebiet hinzuzufügen, brauchen Sie einen Account.',
    area_deleted: 'Gebiet wurde gelöscht.',
    area_updated: 'Gebiet wurde aktualisiert',
    unsubscribed: 'Sie haben sich von der Community abgemeldet.',
    subscribed: 'Sie sind nun Teil dieser Community',
    thread_created: 'Eine neue Diskussion wurde erstellt.',
    thread_updated: 'Die Diskussion wurde aktualisiert',
    comment_created: 'Sie haben eine Antwort gepostet',
    comment_reported: 'Das Bild wurde an die Moderation gemeldet',
    comment_unreported: 'Dieser Inhalt wurde von dem/der Moderator*in gelöscht.',
    comment_deleted: 'Dieser Inhalt wurde gelöscht.',
    community_updated: 'Diese Community wurde aktualisiert.',
    RMS_send: 'Moderator*in-anfrage gesendet',
    poll_created: 'Umfrage erstellt',
    polis_created: 'Polis erstellt',
    meeting_created: 'Treffen erstellt',
    meeting_invite_accepted: 'Sie haben die Einladung angenommen.',
    meeting_invite_declined: 'Sie haben die Einladung abgelehnt.',
    pledge_funding_completed: 'Virtueller Finanzierungsbetrag erfolgreich abgeschlossen.',
    pledge_added: 'Danke für Ihren Beitrag von € '
  },
  errors: {
    general_error: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
    not_found: 'Entschuldigung, hier ist leider nichts...',
    network: 'Netzwerkfehler. Bitte überprüfen Sie Ihre Internetverbindung.',
    // fields: 'Please correct the input fields',
    required: 'Pflichtfeld',
    register: {
      password_length: 'Das Passwort muss mindestens {length} Zeichen lang sein.',
      password_match: 'Passwörter stimmen nicht überein.',
      email_valid: 'Bitte benutzen Sie eine gültige E-Mail Adresse.',
      name_length: ' Benutzer*innenname muss zwischen {minLength} und {maxLength} Zeichen lang sein.',
      organization_length: 'Name der Organisation muss zwischen {minLength} und {maxLength} Zeichen lang sein.'
    }
  },
  buttons: {
    pledge: 'Beitrag zusagen',
    submit: 'Übermitteln',
    logout: 'Ausloggen',
    cancel: 'Abbrechen',
    login: 'Einloggen',
    register: 'Übermitteln',
    // Finn's Comment: I don't quite know where submit is used...
    // go_back: 'Zurück',
    send: 'Senden',
    reSend: 'Erneut senden',
    close: 'Schließen',
    // previous: 'Previous',
    save: 'Speichern',
    create: 'Erstellen',
    update: 'Aktualisieren',
    delete: 'Löschen',
    subscribe: 'Abonnieren',
    unsubscribe: 'Abbestellen',
    next: 'Weiter',
    continue: 'Weiter',
    back: 'Zurück',
    add: 'Hinzufügen',
    edit: 'Bearbeiten',
    reply: 'Antworten',
    report: 'Melden',
    unreport: 'Melden zurückziehen',
    goto_authenticate: 'Zum Log-in',
    stop: 'Stop',
    continue_register: 'Registrierung fortführen',
    continue_participation: 'Beteiligung fortführen',
    become_moderator: 'Moderator*in werden',
    add_image: 'Bild hinzufügen',
    accept: 'Annehmen',
    decline: 'Ablehnen'
  },
  headers: {
    login: 'Einloggen',
    register: 'Registrieren',
    password_forgot: 'Neues Passwort anfragen',
    password_recover: 'Passwort zurücksetzen',
    administrator: 'Administrator*in',
    navigation: 'Navigation',
    my_overview: 'Meine Übersicht',
    overview: 'Übersicht'
  },
  activities: {
    act_cycl: {
      title: 'Fahrrad fahren',
      desciption: 'TODO' // Finn's Comment: TODO as in To-do? There isn't really a good translation for that.

    },
    act_walk: {
      title: 'Wandern oder Joggen',
      desciption: 'TODO'
    },
    act_tour: {
      title: 'Mit dem Auto oder Motorrad herumfahren',
      desciption: 'TODO'
    },
    act_natu: {
      title: 'Natur beobachten',
      desciption: 'TODO'
    },
    act_water: {
      title: 'Wassersport',
      desciption: 'TODO'
    },
    act_play: {
      title: 'Spielen oder Sitzen',
      desciption: 'TODO'
    },
    act_wintr: {
      title: 'Wintersport',
      desciption: 'TODO'
    },
    act_mount: {
      title: 'Bergsteigen',
      desciption: 'TODO'
    }
  },
  labels: {
    account_required: 'Account erforderlich',
    read_more: 'Mehr lesen',
    'area_create_button': 'Speichern',
    'area_update_button': 'Speichern',
    'area_created_succes': 'Gebiet erfolgreich hinzugefügt',
    'area_updated_succes': 'Gebiet erfolgreich aktualisiert',
    'community_created_succes': 'Community wurde erfolgreich erstellt',
    agree_disclaimer: 'Ich stimme den Geschäftsbedingungen zu.',
    my_account: 'Mein Account',
    register: 'Account erstellen',
    logout: 'Ausloggen',
    login: 'Einloggen',
    email: 'E-Mail Adresse',
    name: 'Benutzer*innenname',
    companyName: 'Firmenname',
    organization: 'Name der Organisation',
    sector: 'Sektor',
    address: 'Addresse',
    address_search: 'Addresse',
    // zipcodeCompany: 'Zipcode headquarter',
    website: 'Webseite',
    city: 'Stadt',
    // cityCompany: 'City headquarter',
    age: 'Alter',
    education: 'Bildungsgrad',
    gender: 'Geschlecht',
    type: 'Typ',
    password: 'Passwort',
    repeat_password: 'Passwort wiederholen',
    remember_me: 'Angemeldet bleiben',
    password_forgot: 'Haben Sie ihr Passwort vergessen?',
    home: 'Zuhause',
    here: 'Hier',
    // Finn's Comment: Don't quite know where that goes...
    neighbourhood: 'Nachbarschaft',
    'living-area': 'Region',
    country: 'Land',
    worldwide: 'Weltweit',
    share_this_area: 'Dieses Gebiet teilen',
    share_this_community: 'Diese Community teilen',
    subscribed: 'Aboniert',
    reply: 'Antworten',
    comment: 'Kommentieren',
    finish: 'Abschließen',
    readmore: 'Mehr lesen',
    search: 'Suchen',
    created_on: 'Erstellt am',
    dear: 'Am besten'
  },
  pages: {
    moderators: {
      title: 'Moderator*innen',
      mod_requests: 'Moderator*innen-Anfragen',
      mods: 'Moderator*innen'
    },
    create_community: {
      title: 'Community erstellen'
    },
    land_owner_register: {
      title: 'Registrierung für Landbesitzer*innen',
      steps: {
        general: {
          nav_title: ' Allgemeine Informationen',
          title: 'Information Landbesitzer*innen',
          task_title: 'Fügen Sie allgemeine Informationen über das Land was Sie besitzen hinzu.',
          email: 'E-Mail'
        },
        draw: {
          nav_title: 'Karte',
          title: 'Gebiet zeichnen',
          task_title: 'Zeichnen Sie Ihr Gebiet ein'
        },
        description: {
          nav_title: 'Beschreibung',
          title: 'Beschreiben Sie ihre Community',
          task_title: 'Fügen Sie einen Titel hinzu, der Ihre Community beschreibt',
          input_title: 'Titel',
          input_title_error: 'Maximal 50 Zeichen',
          input_description: 'Beschreibung',
          task_description: 'Beschreiben Sie, was diese Community so interessant, wertvoll, oder wichtig macht.',
          input_description_error: 'Maximal 1000 Zeichen'
        },
        finalize: {
          nav_title: 'Abschließen',
          title: 'Informationen',
          task_title: 'Vielen Dank für das Eingeben dieser Informationen. Wenn Sie auf «Weiter» klicken, schließen wir das Erstellen Ihrer Community ab. Um Themen mit Ihrer Community zu diskutieren, können Sie eine Diskussion eröffnen. Klicken Sie auf die 3 Punkte rechts neben «Diskussionen», um eine neue Diskussion hinzuzufügen.'
        }
      }
    },
    pledge: {
      count: 'Anzahl der Zusagen',
      part1: 'zugesagt von',
      part2: 'Ziel'
    },
    add_pledge: {
      title_nav: 'Zusage hinzufügen',
      title: 'Fügen Sie den Betrag hinzu, den Sie gerne für dieses Projekt zusagen würden.',
      description: 'todo text',
      // Finn's Comment: ??
      amount: 'Beitragshöhe',
      reason: 'Können Sie bitte näher auf den von Ihnen gewählten Betrag eingehen? Warum haben Sie diesen Betrag gewählt?'
    },
    add_treasure: {
      title_nav: 'Fügen Sie einen Schatz hinzu',
      photos: 'Fotos',
      title: 'Fotos vom Schatz hochladen',
      description: 'Beschreiben Sie, warum Ihnen dieser Ort gefällt. Teilen Sie eine Zeichnung, ein Gedicht, ein Bild oder eine Kurzgeschichte.',
      upload: 'Foto hochladen (.jpg)'
    },
    create_pledge: {
      title_nav: 'Zusage hinzufügen',
      title: 'Beschreiben Sie Ihre Finanzierungszusage mit einem einprägsamen Titel.',
      description: 'Beschreiben Sie Ihre Finanzierungszusage genauer. Geben Sie Hintergrundinformationen, vorgeschlagene Maßnahmen, erwartete Ergebnisse und was Ihre Unterstützer im Gegenzug erhalten.',
      type: 'Art der Zusage',
      fixed: 'Fixiert',
      fixed_desc: 'Zusage einmalig anfragen',
      yearly: 'Jährlich',
      yearly_desc: 'Jährlich wiederholende Zusagen anfragen.',
      '2yearly': '2-Jährlich',
      '2yearly_desc': 'Zusagen alle zwei Jahre anfragen.',
      '3yearly': '3-Jährlich',
      '3yearly_desc': 'Zusagen alle drei Jahre anfragen.',
      '4yearly': '4-Jährlich',
      '4yearly_desc': 'Zusagen alle vier Jahre anfragen.',
      '5yearly': '5-Jährlich',
      '5yearly_desc': 'Zusagen alle fünf Jahre anfragen.',
      goal: 'Legen Sie die Höhe der Finanzierungszusage in Euros fest.'
    },
    edit_pledge: {
      title_nav: 'Finanzierungszusage bearbeiten'
    },
    create_thread: {
      title_nav: 'Diskussion erstellen',
      title_nav_edit: 'Diskussion bearbeiten',
      title: 'Geben Sie einen Titel an',
      description: 'Verfassen Sie einen ersten Post',
      upload: 'Foto hochladen (.jpg)'
    },
    create_poll: {
      title_nav: 'Umfrage erstellen',
      title_nav_edit: 'Umfrage bearbeiten',
      title: 'Geben Sie einen Titel an',
      description: 'Verfassen Sie einen ersten Post',
      upload: 'Foto hochladen (.jpg)',
      options: 'Umfrage hinzufügen',
      option: 'Optionen'
    },
    create_polis: {
      title_nav: 'Polis erstellen',
      title_nav_edit: 'Polis bearbeiten',
      title: 'Titel hinzufügen',
      id: 'Polis ID hinzufügen',
      voter_option: 'Nur abonnierte Benutzer*innen können abstimmen.'
    },
    create_meeting: {
      title_nav: 'Polis erstellen',
      title_nav_edit: 'Polis bearbeiten',
      title: 'Titel hinzufügen',
      description: 'Beschreibung hinzufügen',
      url: 'Fügen Sie einen externen URL für das Treffen hinzu.',
      dateTime: 'Startzeit und Datum'
    },
    meeting: {
      total_subs: 'Anzahl der Abonnent*innen',
      accepted_invites: 'Angenommene Einladungen',
      rejected_invites: 'Abgelehnte Einladungen',
      planned_label: 'geplant am',
      invited_banner: 'Sie wurden zum Meeting eingeladen',
      accepted_banner: 'Sie haben die Einladung angenommen. Wenn Sie ausgewählt werden, erhalten Sie eine E-Mail mit weiteren Einzelheiten zum Treffen.',
      rejected_banner: 'Sie haben die Einladung zum Meeting abgelehnt',
      send_banner: 'Senden Sie den Link zum Treffen an Abonnent*innen, die die Einladung angenommen haben.',
      resend_banner: 'Senden Sie den Link zum Treffen erneut. Achtung, Sie haben den Link bereits gesendet!' // Finn's Comment: This was misspelled in the English version.

    },
    landing: {
      my_portal: 'Mein Xena'
    },
    welcome: {
      title: 'Willkommen bei Greenmapper',
      introduction: 'Einleitung',
      paragraph_1: 'Mit Greenmapper Friends können Sie schöne, wertvolle oder für Sie wichtige Naturgebiete markieren. Bei diesen Gebieten muss es sich um Orte mit viel Grün, Wasser oder Natur handeln. Es kann ein Ort in einem Park, an einem See, am Meer, in einem Wald, auf einer Wiese, auf einem Blumenfeld, zur Vogelbeobachtung usw. sein. Es kann ein Ort innerhalb oder außerhalb einer Stadt oder Dorf sein; klein oder groß. Sie können den Ort oft besuchen oder fast nie! Es muss sich nur um einen Ort mit Natur handeln, den Sie schön oder wertvoll finden oder der für Sie von Bedeutung ist.',
      subtitle_2: 'Warum registrieren?',
      paragraph_2: 'Die Idee ist, dass Sie Ihre Lieblingsnaturgebiete angeben, von denen Sie ein*e Freund*in sein wollen. Aus diesem Grund stellen wir Ihnen einige Fragen, um ein Konto für Sie zu erstellen. Ihre E-Mail-Adresse wird in der App nicht angezeigt, sondern nur Ihr Benutzer*innenname.',
      subtitle_3: 'Datenschutzrichtlinie',
      paragraph_3: 'Greenmapper Friends ist eine Initiative der Universität Groningen. Greenmapper Friends speichert Ihre E-Mail-Adresse im Greenmapper Landscape Friend Registry (GLFR). Niemand außer der Greenmapper-Organisation kann auf diese E-Mail zugreifen. Sie können über das GLFR anonym von Grundeigentümer*innen, Landverwalter*innen oder anderen Freund*innen der Region erreicht werden. Diese Dritten haben jedoch keinen direkten Zugriff auf Ihre E-Mail. Wir befolgen die Richtlinien der Europäischen Datenschutz-Grundverordnung zum Datenschutz und zur Speicherung Ihrer personenbezogenen Daten.',
      continue_without_account: 'Ohne Konto fortfahren',
      login_text: 'Falls Sie schon ein Konto haben, können Sie sich hier anmelden',
      forgot_password: 'Passwort vergessen?',
      forgot_password_dialog: 'Bitte geben Sie Ihre E-Mail Adresse ein.'
    },
    introduction: {
      title: 'Einführung',
      text: 'In Greenmapper Friends können Sie schöne, wertvolle oder für Sie wichtige Naturorte markieren. Diese Bereiche müssen Orte mit Grün, Wasser oder Natur sein. Dies kann ein Ort in einem Park, an einem See, am Meer, in einem Wald oder auf einer Wiese sein. Es könnte ein Blumenfeld, oder ein Platz zum Vogelbeobachten sein. Es kann ein Ort innerhalb oder außerhalb einer Stadt oder Dorf sein; klein oder groß. Sie können den Ort oft besuchen oder fast nie! Es muss sich nur um einen Ort mit Natur handeln, den Sie schön oder wertvoll finden oder der für Sie von Bedeutung ist.',
      start_btn: 'Umfrage starten'
    },
    register: {
      disclaimer_title: 'Disclaimer Greenmapper Friends',
      disclaimer: 'Greenmapper Friends speichert Ihre E-Mail-Adresse im Greenmapper Landscape Friend Registry (GLFR). Niemand außer der Greenmapper-Organisation kann auf diese E-Mail zugreifen. Sie können über das GLFR anonym von Grundeigentümern, Landverwaltern oder anderen Freund*innen der Region erreicht werden. Diese Dritten haben jedoch keinen direkten Zugriff auf Ihre E-Mail. Wir befolgen die Richtlinien der Europäischen Datenschutz-Grundverordnung zum Datenschutz und zur Speicherung Ihrer personenbezogenen Daten. Lesen Sie hier mehr dazu.'
    },
    info_contact: {
      title: 'Über uns',
      contact: 'Kontaktieren',
      disclaimer_title: 'Disclaimer',
      implementation: 'Umsetzung: '
    },
    map: {
      title: 'Gesamtkarte der Community',
      add_btn: 'Markieren Sie ein neues Gebiet',
      share_title: 'Karten-URL teilen',
      share_btn: 'In die Zwischenablage kopieren',
      hint_on_add: 'Fügen Sie einen Punkt zur Karte hinzu um mit dem Zeichnen anzufangen',
      hint_on_end_draw: 'Sie können das Gebiet immernoch bearbeiten',
      zoom_to_area_btn: 'Zum Gebiet zoomen',
      details_btn: 'Details ansehen' // share_desc: 'Share'

    },
    timeline: {
      title: 'Zeitachse',
      hint: 'Wussten Sie, dass Wandern die beliebteste Aktivität in Lieblingsnaturgebieten ist?',
      rating: 'Bewertung',
      rating_subtext: 'Persönliche Bewertung der markierten Gebiete',
      visit_frequency: 'Besuchshäufigkeit',
      visit_frequency_subtext: 'Wie oft Sie dieses Gebiet besuchen',
      activities: 'Aktivitätsart',
      activities_subtext: 'Art der Aktivitäten im Gebiet',
      add_btn: 'Gebiet hinzufügen',
      edit_btn: 'Gebiet bearbeiten',
      delete_btn: 'Gebiet löschen',
      delete_title: 'Gebiet löschen',
      delete_subtitle: 'Sind Sie sicher?'
    },
    add_area_info: {
      title: 'Gebiet hinzufügen',
      add_btn: 'Gebiet hinzufügen'
    },
    my_areas: {
      title: 'Meine Gebiete',
      sub_title: 'Alle Naturorte, die ich schön, wertvoll oder wichtig finde',
      created_areas: 'Markierte Gebiete',
      joint_surface: 'Gesamtfläche (km²)'
    },
    my_communities: {
      title: 'Meine Communities',
      sub_title: 'Alle Gebiete von Grundstückseigentümer*innen oder Gebietsverwalter*innen, die sich mit meinen Bereichen überschneiden. Hier sind Sie als Freund*in dieser Bereiche erreichbar.',
      delete_btn: 'Abmelden',
      delete_title: 'Abmelden',
      delete_subtitle: 'Sind Sie sicher, dass Sie sich von dieser Community abmelden möchten?',
      befriended_communities: 'Befreundete Communities',
      joint_surface: 'Gesamtfläche (km²)'
    },
    info: {
      text1: 'Greenmapper Friends wurde von Greenmapper entwickelt. Weitere Informationen finden Sie im Greenmapper.',
      text2: 'Wenn Sie Fragen haben oder mit uns Kontakt aufnehmen möchten, senden Sie uns bitte eine E-Mail an:',
      text3: 'Bei Greenmapper Friends versuchen wir, die App und die Daten so genau und fehlerfrei wie möglich zu halten. Sollte die App trotz unserer Bemühungen unvollständige oder ungenaue Informationen enthalten, können wir hierfür keine Verantwortung übernehmen.',
      text4: 'Wir behalten uns das Recht vor, Inhalte ohne vorherige Ankündigung zu ändern, zu entfernen oder hinzuzufügen. Greenmapper Friends übernimmt keine Haftung für die Inhalte von Webseiten, auf die wir per Link verweisen.'
    },
    my_owned_communities: {
      title: 'Meine Communities',
      owned_communities: 'Eigene Communities',
      total_subs: 'Anzahl der Abonnent*innen',
      joint_surface: 'Gesamtfläche (km²)'
    },
    community: {
      report_title: 'Kommentar melden',
      report_subtitle: 'Sind Sie sicher, dass Sie diesen Kommentar an die Moderation melden möchten?',
      report_hint: 'Benennen Sie einen Grund',
      delete_title: 'Inhalt löschen',
      delete_subtitle: 'Sind Sie sicher, dass sie diesen Inhalt löschen möchten?',
      discussions_title: 'Diskussionen',
      discussions_subtitle: 'Offene Diskussionen zu jedem Thema',
      poll_title: 'Umfrage',
      poll_subtitle: 'Einfache Umfrage',
      polis_title: 'Pol.is',
      polis_subtitle: 'Fortschrittliche, interaktive Umfragen mit Stellungnahme zu Aussagen Pol.is',
      pledges_title: 'Zusagen',
      pledges_subtitle: 'Virtuelle Geldzusagen für Biodiversität, Landschaftsqualität, oder nachhaltige Dienste Ausführlicher Online-Workshop oder Treffen mit Freund*innen',
      meetings_title: 'Treffen',
      meetings_subtitle: 'Ausführlicher Online-Workshop oder Treffen mit Freund*innen',
      RMS_dialog_title: 'Moderator*innenstatus anfordern',
      RMS_dialog_text: 'Möchten Sie eine Anfrage senden, um Moderator*in dieser Community zu werden?'
    },
    polis: {
      text: 'Klicken Sie auf "Übersetzung ausschalten", um die Kommentare in Ihrer Originalsprache anzuzeigen. Für jede Aussage können Sie mit „Ich stimme zu“, „Ich stimme nicht zu“ oder „Weiter / Ich bin mir unsicher“ stimmen.',
      text2: 'Sie können Ihre Meinung oder Aussage einreichen, damit andere über Ihre Ideen abstimmen können.'
    },
    account: {
      edit_title: 'Profil bearbeiten',
      edit_text: 'Aktuelle Profilinformationen bearbeiten',
      edit_btn: 'Profil bearbeiten',
      signout_title: 'Ausloggen',
      signout_text: 'Aus dem Account ausloggen',
      signout_btn: 'Ausloggen'
    },
    create_area: {
      title: 'Gebiet erstellen',
      home_title: 'Markieren Sie Ihr Zuhause',
      stop_title: 'Registrierung stoppen und später beenden',
      stepper: {
        description: 'Beschreibung',
        rate: 'Bewertung',
        visit_freq: 'Besuchshäufigkeit',
        activities: 'Aktivitätsart ',
        connected: 'Online verbunden'
      },
      steps: {
        level: {
          nav_title: 'Umkreis',
          title: 'Umkreis auswählen',
          task_title: 'Wählen Sie aus, in welchem Umkreis das Gebiet für Sie liegt.',
          options: {
            neighborhood: 'Nachbarschaft',
            living_area: 'Region',
            country: 'Land',
            world: 'Weltweit'
          }
        },
        map: {
          nav_title: 'Karte',
          title: 'Gebiet zeichnen',
          task_title: 'Zeichnen Sie Ihr Gebiet ein.'
        },
        description: {
          nav_title: 'Beschreiben Sie',
          title: 'Beschreibung',
          task_title: 'Geben Sie dem Gebiet einen Titel, der es gut beschreibt',
          input_title: 'Titel',
          input_title_error: 'Maximal 50 Zeichen',
          input_description: 'Beschreibung',
          task_description: 'Beschreiben Sie in eigenen Worten, was die Gegend für Sie attraktiv, wertvoll oder wichtig macht',
          input_description_error: 'Maximal 100 Zeichen'
        },
        rate: {
          nav_title: 'Bewertung',
          title: 'Bewerten Sie Ihr Gebiet',
          task_title: '(Bewertung: 1 = Schlecht, 6 = Mittel, 10 = Perfekt)'
        },
        visit_freq: {
          nav_title: 'Besuchshäufigkeit',
          title: 'Wie oft besuchen Sie Ihr Gebiet?',
          options: {
            daily: 'Täglich',
            weekly: 'Wöchentlich',
            monthly: 'Monatlich',
            few_times_a_year: 'Ein paar Mal pro Jahr',
            yearly: 'Jährlich',
            rarely: 'Manchmal',
            never: 'Nie'
          }
        },
        activities: {
          nav_title: 'Aktivitätsart',
          title: 'Welche Aktivitäten unternehmen Sie in diesem Gebiet?'
        },
        priority: {
          nav_title: 'Priorität',
          title: 'Dieser Ort muss:',
          options: {
            maintained: 'So beibehalten werden',
            strengthened: 'Verbessert werden'
          }
        },
        relationship: {
          nav_title: 'Verbindung',
          title: 'Was ist Ihre Verbindung mit diesem Lieblingsnaturgebiet?',
          options: {
            tourist: 'Tourist',
            inhabitant: 'Anwohner*in',
            work: 'Arbeitsbezogen'
          }
        }
      },
      _edit: {
        title: 'Gebiet aktualisieren'
      }
    },
    edit_area: {
      title: 'Gebiet aktualisieren'
    },
    funding: {
      title: 'Virtuelle Natur & Community Mittel',
      nav_intro: 'Einleitung',
      nav_amount: 'Höhe',
      nav_locations: 'Orte',
      nav_objectives: 'Ziele',
      nav_finalize: 'Abschließen',
      intro1: 'Liebe*r Teilnehmer*in,',
      intro2: 'REMOVED',
      intro3: 'Vielen Dank für Ihre Teilnahme an unserem Greenmapper-Finanzierungsexperiment. Sie sind ein Fan verschiedener Gebiete, wie zum Beispiel des Nationalparks Duinen van Texel. Die nachhaltige Entwicklung dieser Gebiete erfordert möglicherweise zusätzliche finanzielle Unterstützung. Eine globale Herausforderung ist oft die langfristige Finanzierung der Natur, wie zum Beispiel der Erhalt von Biodiversität und eines attraktiven Landschaftsbildes, aber auch die nachhaltige Gestaltung der Wirtschaft im Lieblingsgebiet und die Veranstaltung kulturelle Aktivitäten. Zu diesem Zweck haben wir für jedes Ihrer Lieblingsnaturgebiete einen virtuellen Investmentfonds zusammengestellt. Wir nennen diese Fonds „Virtual Nature & Community Funds“. Auf diese Weise können Sie (für dieses Experiment fiktiv) einen Beitrag zu der Gegend leisten, die Sie so gerne besuchen. Ein Virtual Nature & Community-Fonds sieht so aus:',
      intro4: 'Die (virtuellen) Mittel für einen Bereich landen in einem Fonds. Hier sind sie basierend auf Ihrer Verteilung in Kategorien unterteilt. Die Mittel werden dann pro Kategorie auf bestimmte Projekte verteilt. Obwohl die Mittel also in einem Fonds zusammengefasst sind, sind sie gezielt Kategorien und Projekten zugeordnet. Wir sind gespannt, wie Sie ein Budget zwischen den virtuellen Natur- und Community-Fonds Ihrer Lieblingsgebiete aufteilen würden. Liegen Ihnen eher der Erhalt der Artenvielfalt, ein attraktives Landschaftsbild, eine nachhaltigere Gestaltung der lokalen Wirtschaft, Kultur oder etwas anderes am Herzen?',
      intro5: 'Das Experiment dauert ca. 5-8 Minuten und Ihre Antworten werden anonym bearbeitet. Bitte verwenden Sie nur den „Zurück“-Button unten links, um einen Schritt zurück zu gehen. Durch Klicken auf den Pfeil oben links werden Ihre Antworten gelöscht. Nehmen Sie sich Zeit, die Fragen zu beantworten und seien Sie ehrlich. Es gibt keine richtigen oder falschen Antworten. Ihre anonymisierten Antworten sind wertvoll für die akademische Forschung. Sie können sich deshalb dafür entscheiden, Ihre persönlichen Daten ohne Konsequenzen nicht mitzusenden. Bei weiteren Fragen kontaktieren Sie mich bitte per E-Mail (j.beverdam@rug.nl). Durch die Teilnahme an dieser Nature-Finance-Studie stimmen Sie der Verarbeitung Ihrer anonymisierten personenbezogenen Daten zu Forschungszwecken zu.',
      amount1: 'Virtueller Finanzierungsbetrag',
      amount2: 'Zunächst bitten wir Sie, sich über ein mögliches Budget Gedanken zu machen, das Sie bereit wären, für alle virtuellen Natur- und Community-Fonds insgesamt auszugeben. Bitte beachten Sie, dass dies völlig virtuell und hypothetisch ist. Wir empfehlen Ihnen jedoch, ein möglichst realistisches Budget zu wählen. Wählen Sie ein virtuelles, aber realistisches Jahresbudget.',
      amount3: 'Wählen Sie ein virtuelles, aber realistisches Jahresbudget.',
      amount4: 'Könnten Sie bitte erklären, welchen virtuellen Betrag Sie gewählt haben? Warum haben Sie diesen Betrag gewählt?',
      amount5: 'Jahresbudget',
      max: 'Maximum Betrag 5000 euro',
      min: 'Minimum Betrag 1 euro',
      locations1: 'Budgetverteilung über die virtuellen Natur- und Community Fonds Ihrer Lieblingsnaturgebiete',
      locations2: 'Bitte geben Sie an, wie Sie Ihr gewähltes Budget auf die virtuellen Natur- und Community Fonds Ihrer Lieblingsnaturgebiete aufteilen würden.',
      locations3: 'Könnten Sie bitte die von Ihnen gewählte Verteilung der virtuellen Natur- und Community Mittel auf Ihre Lieblingsnaturgebiete erläutern? Warum haben Sie sich für diese Verteilung entschieden?',
      objectives1: 'Budgetverteilung auf die Finanzierungszwecke',
      objectives2: 'Ihre Verteilung der virtuellen Natur- und Community Mittel auf jedes Ihrer Gebiete wird hier pro Gebiet angezeigt. Bitte geben Sie für jedes Ihrer Lieblingsgebiete (lokal, regional, national, global) an, wie Sie das zugewiesene Budget auf die vier Förderziele verteilen würden: Erhaltung der Artenvielfalt, schönes Landschaftsbild, Förderung der Nachhaltigkeit der lokalen Wirtschaft in Ihrem Lieblingsgebiet, oder Kultur. Wenn Sie etwas finanzieren möchten, das nicht in diese Kategorien fällt, können Sie es unten beschreiben und Fördermittel zuweisen.',
      objectives3: 'Would you please elaborate on the allocation of your chosen funding with respect to the three funding objectives? Why did you choose the allocation you did?',
      biodiversity_title: 'Erhalt der Biodiversität',
      biodiversity_text: 'Eine reiche Artenvielfalt trägt zum Funktionieren von Ökosystemen bei und ist entscheidend für den Erhalt allen Lebens auf der Erde. Projekte zum Schutz der biologischen Vielfalt zielen darauf ab, die biologische Vielfalt, oft ganz lokal, zu verbessern oder zu erhalten. Solche Projekte können zum Beispiel die Verbesserung der Boden- und Wasserqualität oder die Installation von Nistkästen für Vögel sein.',
      ALC_title: 'Schönes Landschaftsbild',
      ALC_text: 'Ein schönes Landschaftsbild bezieht sich auf die Aussicht und den Genuss einer Landschaft. Ein solches Projekt kann den Schutz oder die Wiederherstellung von Landschaftselementen wie Hecken, die Renaturierung der Landschaft, oder die Schaffung von Wander- oder Radwegen durch die Landschaft umfassen.',
      SUSBUS_title: 'Nachhaltigkeit in der lokalen Wirtschaft',
      SUSBUS_text: 'Nachhaltige Unternehmen üben ihre Wirtschaftstätigkeit aus, ohne negative Auswirkungen auf ihre (lokale) Umwelt zu verursachen. Projekte, die auf mehr Nachhaltigkeit in der lokalen Wirtschaft abzielen, können den Übergang von einem konventionellen Bauernhof zu einem biologischen oder naturintegrierten Bauernhof beinhalten. Es kann sich auch um den Aufbau eines Netzwerks lokaler Unternehmen und Organisationen handeln, die Abfall und Umweltverschmutzung durch Zusammenarbeit reduzieren.',
      finalize1: 'Vielen Dank für Ihre Teilnahme an dieser Studie von der Universität Groningen zur Finanzierung der Natur. Ihre Antworten sind für uns sehr wertvoll. Klicken Sie bitte auf die Schaltfläche „Speichern“, um Ihre Antworten zu übermitteln.',
      finalize1A: 'Für den dritten Teil des Experiments, werden Sie nun zur Greenmapper Friends-Community Nationalpark Duinen van Texel weitergeleitet. Hier haben wir sechs Finanzierungsanfragen zu echten Herausforderungen im Zusammenhang mit Projekten auf Texel entwickelt und veröffentlicht. Lesen Sie diese bitte sorgfältig durch und überlegen Sie, ob Sie die Projekte finanziell unterstützen würden oder warum nicht. Sie können die Finanzierungszusage und Ihre Begründung für jede Anfrage einzeln übermitteln. <b>Falls Sie für eine bestimmte Anfrage keine Mittel zusagen möchten, können Sie einen Betrag von 0,00 € angeben und erklären, warum Sie <u>keine</u> Finanzierungsmittel geben möchten.</b> Ihre Begründung ist uns sehr wichtig.',
      finalize1B: 'Wenn Sie auf alle sechs Förderanträge geantwortet haben, ist das Experiment abgeschlossen und wir spenden 5,00€ pro Teilnehmer*in mit einem Höchstbetrag von 2000€, um den beliebtesten Förderantrag von Texel zu realisieren.',
      finalize1C: 'Nachdem Sie auf eine Anfrage geantwortet haben, können Sie über «Meine Communities» im Menü links zur Community «Nationalpark Duinen van Texel» zurückkehren, um auf den nächsten Antrag zu antworten.',
      finalize2: 'Bitte beachten Sie, dass ein Widerruf Ihrer übermittelten Daten ohne Folgen möglich ist. Wenn Sie dies tun möchten oder andere Fragen zur Forschung haben, senden Sie bitte eine E-Mail an j.beverdam@rug.nl',
      cultural_title: 'Kulturprojekte',
      cultural_text: 'Naturgebiete können auch ein kulturelles Erbe oder andere kulturelle Aspekte enthalten. Beispielsweise, wenn historische Gebäude oder Denkmäler vorhanden sind oder bestimmte kulturelle Ereignisse damit verbunden sind. Kulturprojekte können die Organisation von (Musik-)Veranstaltungen, Bildungsausflügen oder die Erhaltung von landschaftlichem Erbe beinhalten.',
      other_title: 'Weiteres',
      other_text: 'Möchten Sie noch etwas in Ihrem Lieblingsnaturgebiet fördern, das oben aber nicht genannt wurde? Dann nennen Sie es doch selbst! Mit diesem Schieberegler können Sie angeben, wie viel von Ihrem Budget Sie dafür zur Verfügung stellen würden. Dies ist nicht verpflichtend, sondern nur falls Ihnen etwas einfällt, das nicht in die vorherigen Förderzielkategorien passt.',
      placeholder: 'Weiteres, namentlich'
    }
  },
  drawer: {
    area_section: 'Entdecken',
    my_section: 'Meine Übersicht',
    other_section: 'Weiteres'
  },
  gender: {
    male: 'Männlich',
    female: 'Weiblich',
    other: 'Divers'
  },
  education: {
    1: 'Weniger als Hochschulreife',
    2: 'Hochschulreife',
    3: 'Hochschule ohne Abschluss',
    4: 'Hochschulabschluss',
    5: 'Bachelor',
    6: 'Master',
    7: 'Berufsschulabschluss/ Abgeschlossene Lehre',
    8: 'Doktortitel'
  }
};